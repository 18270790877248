import React, {useEffect} from 'react';
import "./style.css"
import {Instagram, LinkedIn, GitHub, Email} from '@mui/icons-material';
import {IconButton} from "@mui/material";

function App() {
    useEffect(() => {
        const createStar = () => {
            const star = document.createElement('div');
            star.className = 'star';
            const size = Math.random() * 3 + 1;
            star.style.width = `${size}px`;
            star.style.height = `${size}px`;
            star.style.left = `${Math.random() * 100}%`;
            star.style.top = `${Math.random() * 100}%`;
            star.style.animation = `twinkle ${Math.random() * 5 + 2}s infinite`;
            document.querySelector('.starry-night')?.appendChild(star);
        };

        for (let i = 0; i < 50; i++) {
            createStar();
        }
    }, []);

    const iconStyle = {
        color: 'white',
        fontSize: '60px',
    }

    return (
    <div>
    <div className="starry-night">
        <div className="planet"></div>
        <div className="introduction">E L S A _ L O S S I U S</div>
    </div>
        <div className="information">
       24 år gammel webutvikler som starter 100% utviklerjobb på nyåret. Kontakt meg for mer informasjon. Jeg kan lage din nettside eller være en produksjonsassistent. Legger ut på reise til sørøst asia snart.
        </div>
        <div className="footer">
            <IconButton
                aria-label="Instagram"
                href={"https://www.instagram.com/elsalos/"}
                target="_blank"
            >
                <Instagram style={iconStyle} />
            </IconButton>
            <IconButton
                aria-label="Instagram"
                href={"https://linkedin.com/in/elsa-lossius-a44122189"}
                target="_blank"
            >
                <LinkedIn style={iconStyle} />
            </IconButton>
            <IconButton
                aria-label="Instagram"
                href={"https://github.com/Elsa-tech"}
                target="_blank"
            >
                <GitHub style={iconStyle} />
            </IconButton>
            <IconButton
                aria-label="Instagram"
                href={"mailto:elsalos@icloud.com"}
                target="_blank"
            >
                <Email style={iconStyle} />
            </IconButton>
        </div>
    </div>
  );
}

export default App;
